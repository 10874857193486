import TransactionService from './transaction.service'
import apiutil from '@/util/apiutil'
import ipaddress from 'ipaddr.js'
import Typechecker from '@/util/typechecker'
import vniutil from '@/util/vniutil'

export default {
  escapeRegExp(string, content_is_regexp) {
    if (content_is_regexp) {
      return string.slice(2)
    }
    if (string === undefined || content_is_regexp) {
      return string
    }
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  },
  checkRegex(term, require_start = false) {
    if (!term) {
      return term
    }
    const content_is_regex = term.startsWith('s/')
    return '(?i)' + (require_start ? '' : '.*') + this.escapeRegExp(term, content_is_regex) + '.*'
  },
  searchMgr(config, term) {
    return TransactionService.execute(config, [
      {
        name: 'cntl.mgr.list',
        idx: 'login_name',
        old: apiutil.denullify_dict({
          login_name_regexp: this.checkRegex(term, true),
          fetch_limit: 10,
          is_sub: false
        })
      },
      {
        name: 'cntl.mgr.list',
        idx: 'full_name',
        old: apiutil.denullify_dict({
          full_name_regexp_ic: this.checkRegex(term, false),
          fetch_limit: 10,
          is_sub: false
        })
      },
      {
        name: 'cntl.mgr.list',
        union_join_ref: {
          login_name: 'self',
          full_name: 'self'
        }
      }
    ])
  },
  searchBCDRegex(config, term, own, limit) {
    return TransactionService.execute(config, [this.searchBCDRegexTA(term, own, limit)])
  },
  searchBCDRegexTA(term, own, limit) {
    return {
      name: 'nd.bcd.list',
      old: apiutil.denullify_dict({
        name_regexp: this.checkRegex(term),
        is_own: own,
        fetch_limit: limit,
        sorting_params_list: ['name']
      })
    }
  },
  searchOURegexTA(term, own, limit) {
    return {
      name: 'org.unit.list',
      old: apiutil.denullify_dict({
        name_regexp: this.checkRegex(term),
        fetch_limit: limit,
        sorting_params_list: ['short_name']
      })
    }
  },
  searchOUShortRegexTA(term, own, limit) {
    return {
      name: 'org.unit.list',
      old: apiutil.denullify_dict({
        short_name_regexp: this.checkRegex(term),
        fetch_limit: limit,
        sorting_params_list: ['short_name']
      })
    }
  },
  searchOUDirectTA(term, limit) {
    return {
      name: 'org.unit.list',
      old: apiutil.denullify_dict({
        short_name: term.toUpperCase(),
        fetch_limit: limit,
        sorting_params_list: ['short_name']
      })
    }
  },
  searchGroupDirectTA(term, limit) {
    return {
      name: 'cntl.group.list',
      old: apiutil.denullify_dict({
        name: term,
        fetch_limit: limit,
        sorting_params_list: ['name']
      })
    }
  },
  searchGroupRegexTA(term, limit) {
    return {
      name: 'cntl.group.list',
      old: apiutil.denullify_dict({
        name_regexp: this.checkRegex(term),
        fetch_limit: limit,
        sorting_params_list: ['name']
      })
    }
  },
  searchFQDNRegexTA(term, own, limit) {
    return {
      name: 'dns.fqdn.list',
      old: apiutil.denullify_dict({
        value_regexp: this.checkRegex(term),
        is_own: own,
        fetch_limit: limit,
        sorting_params_list: ['value']
      })
    }
  },
  search_macauth_client(term, own, limit) {
    return {
      name: 'macauth.client.list',
      old: apiutil.denullify_dict({
        mac_addr: term.replace(/[^0-9a-fA-F]/g, ''),
        is_own: own,
        fetch_limit: limit
      })
    }
  },
  searchRecordRegexTA(term, own, limit) {
    return {
      name: 'dns.record.list',
      old: apiutil.denullify_dict({
        fqdn_regexp: this.checkRegex(term),
        is_own: own,
        fetch_limit: limit,
        is_auth: true,
        sorting_params_list: ['fqdn']
      })
    }
  },
  searchRRDataRegexTA(term, own, limit) {
    return {
      name: 'dns.record.list',
      old: apiutil.denullify_dict({
        data_regexp: this.checkRegex(term),
        is_own: own,
        is_auth: true,
        fetch_limit: limit,
        sorting_params_list: ['data']
      })
    }
  },
  searchRRByIPTA(ip, own, limit) {
    return {
      name: 'dns.record.list',
      old: apiutil.denullify_dict({
        is_own: own,
        is_auth: true,
        fetch_limit: limit,
        sorting_params_list: ['target_ipaddr']
      }),
      inner_join_ref: { 0: 'default' }
    }
  },
  searchVlanTA(term, limit) {
    return {
      name: 'nd.vlan.list',
      old: apiutil.denullify_dict({
        id: term,
        fetch_limit: limit
      })
    }
  },
  searchBCDSubnetTA(term, own, limit) {
    return {
      name: 'nd.ip_subnet.list',
      old: apiutil.denullify_dict({
        cidr: term,
        is_own: own,
        fetch_limit: limit,
        cidr_operator: 'smallest_cts'
      })
    }
  },
  searchVNITA(term, own, limit) {
    if (vniutil.is_bcd_id(term)) {
      term = term.replaceAll('.', '')
    }
    term = parseInt(term)
    return {
      name: 'nd.vxlan.list',
      old: apiutil.denullify_dict({
        vni: term,
        is_own: own,
        fetch_limit: limit
      })
    }
  },
  searchDeviceTA(term, own, limit) {
    return {
      name: 'nd.device.list',
      old: apiutil.denullify_dict({
        fqdn_regexp: this.checkRegex(term),
        fetch_limit: limit,
      })
    }
  },
  getIPsTA(ip, limit) {
    return {
      name: 'dns.ip_addr.list',
      old: {
        value_cidr: ip,
        fetch_limit: limit
      }
    }
  },
  async searchGlobal(vm, term, discriminator = null, limit = 20, own = true) {
    if (term === '') {
      return
    }
    const config = vm.$store.state
    const termIsNumeric = /^(0|[1-9][0-9]*)$/.test(term)
    const termIsIP = ipaddress.isValid(term.split('/')[0]) && !termIsNumeric
    const termCanBeBCD = await Typechecker.check_input(vm, 'nd', 'bcd', 'list', 'name', term)
    const termCanBeFQDN = await Typechecker.check_input(vm, 'dns', 'fqdn', 'list', 'value', term)
    const termCanBeOU = await Typechecker.check_input(vm, 'org', 'unit', 'list', 'name_regexp', term)
    const termCanBeGroup = await Typechecker.check_input(vm, 'cntl', 'group', 'list', 'name', term)
    const termCanBeMacAddr = term.replace(/[^0-9a-fA-F]/g, '').length === 12
    const termCanBeVNI = termIsNumeric || vniutil.is_bcd_id(term)
    const transactions = []
    const types = []
    if (termIsIP) {
      transactions.push(this.getIPsTA(term, limit))
      types.push(null)
    }
    const searches = {
      dns: [
        () => {
          if (termIsNumeric) {
            transactions.push(this.searchVlanTA(parseInt(term), limit))
            types.push('nd.vlan')
          } else if (termIsIP) {
            transactions.push(this.searchRRByIPTA(term, own, limit))
            types.push('dns.record')
            transactions.push(this.searchBCDSubnetTA(term, own, limit))
            types.push('nd.ip_subnet')
          } else {
            if (termCanBeBCD) {
              transactions.push(this.searchBCDRegexTA(term, own, limit))
              types.push('nd.bcd')
            }
            transactions.push(this.searchRecordRegexTA(term, own, limit))
            types.push('dns.record')
            transactions.push(this.searchRRDataRegexTA(term, own, limit))
            types.push('dns.record')
            if (termCanBeFQDN) {
              transactions.push(this.searchFQDNRegexTA(term, own, limit))
              types.push('dns.fqdn')
              transactions.push(this.searchDeviceTA(term, own, limit))
              types.push('nd.device')
            }
          }
          if (termCanBeMacAddr) {
            transactions.push(this.search_macauth_client(term))
            types.push('macauth.client')
          }
          if (termCanBeVNI) {
            transactions.push(this.searchVNITA(term, own, limit))
            types.push('nd.vxlan')
          }
        }
      ],
      org: [
        () => {
          if (!termIsIP && termCanBeOU) {
            transactions.push(this.searchOURegexTA(term, null, limit))
            types.push('org.unit')
            transactions.push(this.searchOUShortRegexTA(term, null, limit))
            types.push('org.unit')
          }
        }
      ],
      cntl: [
        () => {
          if (!termIsIP && termCanBeGroup) {
            transactions.push(this.searchGroupDirectTA(term, limit))
            types.push('cntl.group')
            transactions.push(this.searchGroupRegexTA(term, limit))
            types.push('cntl.group')
          }
        }
      ]
    }
    const discriminatorAliases = {
      dnsvs: 'dns'
    }
    if (discriminator == null) {
      for (const d in searches) {
        for (const s of searches[d]) {
          s()
        }
      }
    } else if (discriminator in searches) {
      for (const s of searches[discriminator]) {
        s()
      }
    } else if (discriminator in discriminatorAliases) {
      for (const s of searches[discriminatorAliases[discriminator]]) {
        s()
      }
    }
    return {
      promise: TransactionService.executeList(config, transactions),
      types: types,
      term: discriminator ? discriminator + ':' + term : term
    }
  }
}
