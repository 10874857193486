<template>
  <b-modal v-if="object_definition != null"
           size="lg" scrollable :busy="object_definition == null"
           :id="modal_id"
           :title="functionWithObject2text(object_function, object_definition.name.replace(/^\w/, c => c.toUpperCase()) + (object_title==null?'':': ' + object_title) )"
           :static="false"
           ref="modal"
           @show="shown">
    <template #modal-footer="{ cancel }">
      <b-button @click="cancel()" variant="outline-secondary">{{ $t('system.cancel') }}</b-button>
      <b-button @click="commit(true)" :variant="'outline-'+function2variant(object_function)"
                v-if="object_function === 'create'">
        {{ function2text(object_function, 2).replace(/^\w/, c => c.toUpperCase()) }}
      </b-button>
      <b-button @click="commit(false)" :variant="function2variant(object_function)">
        {{ function2text(object_function).replace(/^\w/, c => c.toUpperCase()) }}
      </b-button>
    </template>
    <form style="min-height: 400px" ref="form" :id="modal_id + '-dbeditform'" v-if="object_definition != null"
          @submit.stop.prevent="commit(false)">
      <b-button type="submit" style="z-index: -1; position:absolute; opacity: 0" tabindex="-1">.
      </b-button>
      <template v-if="!non_optionals_order">
        <h6>
          {{ $t('components.db_editor.additional_params') }}
        </h6>
        <div v-for="(v, id) in template.variables" :key="v.id">
          <JSONTemplateDataEditFieldList v-if="presets && 'list' in v && v.list" :variable="v" :variable_id="id"
                                         :value="presets[id]"/>
          <JSONTemplateDataEditField v-else-if="presets" :value="presets[id]" :variable="v" :variable_id="id"/>
        </div>
        <hr/>
        <h6>
          {{ $t('components.db_editor.object_params') }}
        </h6>
        <template v-for="(data,attr) in object_functions[object_function].parameters">
          <APIObjectDataEditField :object_attribute="data" :attribute_preset="computedPresets[attr]"
                         :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                         :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                         :attribute_name="attr"
                         :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                         :input_reducer="input_reducer[attr]"
                         v-bind:key="attr"
                         v-if="'new' in data && (data.new.isRequired || non_optionals.includes(attr))"></APIObjectDataEditField>
        </template>
        <template>
          <b-link v-b-toggle.collapse-optional>{{ $tc('components.db_editor.advanced_parameter', 2) }}</b-link>
          <b-collapse id="collapse-optional">
            <hr/>
            <template v-for="(data,attr) in object_functions[object_function].parameters">
              <APIObjectDataEditField :object_attribute="data" :attribute_preset="computedPresets[attr]"
                             :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                             :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                             :attribute_name="attr"
                             :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                             :input_reducer="input_reducer[attr]"
                             v-bind:key="attr"
                             v-if="'new' in data && (!data.new.isRequired && !non_optionals.includes(attr))"></APIObjectDataEditField>
            </template>
            <hr/>
            <b-checkbox v-model="show_result_int">{{ $t('components.db_editor.request_result') }}</b-checkbox>
          </b-collapse>
        </template>
      </template>
      <template v-else>
        <template v-for="attr in non_optionals_order">
          <APIObjectDataEditField :object_attribute="object_functions[object_function].parameters[attr]"
                                  :attribute_preset="computedPresets[attr]"
                                  :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                                  :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                                  :attribute_name="attr"
                                  :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                                  :input_reducer="input_reducer[attr]"
                                  v-if="attr in object_functions[object_function].parameters && !object_functions[object_function].parameters[attr].data_type.endsWith('_array')"
                                  v-bind:key="attr"
          />
          <APIObjectDataEditFieldList :object_attribute="object_functions[object_function].parameters[attr]"
                                  :attribute_preset="computedPresets[attr]"
                                  :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                                  :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                                  :attribute_name="attr"
                                  :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                                  :input_reducer="input_reducer[attr]"
                                  v-if="attr in object_functions[object_function].parameters && object_functions[object_function].parameters[attr].data_type.endsWith('_array')"
                                  v-bind:key="attr"
          />
          <template v-else-if="attr in template.variables">
            <JSONTemplateDataEditFieldList
                icon="transaction_additional"
                v-if="presets && 'list' in template.variables[attr] && template.variables[attr].list"
                :variable="template.variables[attr]" :variable_id="attr"
                :value="presets[attr]" v-bind:key="attr"/>
            <JSONTemplateDataEditField icon="transaction_additional" v-else-if="presets" :value="presets[attr]"
                                       :variable="template.variables[attr]"
                                       :variable_id="attr" v-bind:key="attr"/>
          </template>
        </template>
        <template>
          <b-link v-b-toggle.collapse-optional>{{ $tc('components.db_editor.advanced_parameter', 2) }}</b-link>
          <b-collapse id="collapse-optional">
            <hr/>
            <div v-for="(v, id) in template.variables" :key="v.id">
              <div v-if="!non_optionals_order.includes(id)">
                <JSONTemplateDataEditFieldList icon="transaction_additional" v-if="presets && 'list' in v && v.list"
                                               :variable="v" :variable_id="id"
                                               :value="presets[id]"/>
                <JSONTemplateDataEditField icon="transaction_additional" v-else-if="presets" :value="presets[id]"
                                           :variable="v" :variable_id="id"/>
              </div>
            </div>
            <template v-for="(data,attr) in object_functions[object_function].parameters">
              <APIObjectDataEditField :object_attribute="data" :attribute_preset="computedPresets[attr]"
                                      :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                                      :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                                      :attribute_name="attr"
                                      :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                                      :input_reducer="input_reducer[attr]"
                                      v-bind:key="attr"
                                      v-if="'new' in data && !non_optionals_order.includes(attr) && !data.data_type.endsWith('_array')"/>
              <APIObjectDataEditFieldList :object_attribute="data" :attribute_preset="computedPresets[attr]"
                                          :attribute_reference_name="object_definition_reference_names_by_attribute[attr]"
                                          :attribute_referencing="object_definition_referencing_by_attribute[attr]"
                                          :attribute_name="attr"
                                          :attribute_non_expert_nullable="nullable_non_expert.includes(attr)"
                                          :input_reducer="input_reducer[attr]"
                                          v-bind:key="attr"
                                          v-if="'new' in data && !non_optionals_order.includes(attr) && data.data_type.endsWith('_array')"/>
            </template>
            <hr/>
            <b-checkbox @change="$emit('show_result_change', show_result_int)" v-model="show_result_int">
              {{ $tc('components.db_editor.show_returned_element_on_success', 1) }}
            </b-checkbox>
          </b-collapse>
        </template>
      </template>
    </form>
  </b-modal>
</template>

<script>
import TransactionUtil from '@/util/transactionutil'
import APIObjectDBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import JSONTemplateDataEditField from '@/components/db-editor/JSONTemplateDataEditField.vue'
import JSONTemplateDataEditFieldList from '@/components/db-editor/JSONTemplateDataEditFieldList.vue'
import APIObjectDataEditField from '@/components/db-editor/APIObjectDataEditField.vue'
import APIObjectDataEditFieldList from '@/components/db-editor/APIObjectDataEditFieldList.vue'

const props = Object.assign({}, APIObjectDBEditor.props)
props.template = {
  type: Object,
  required: true
}
delete props.only_emit_ta
export default {
  name: 'APIObjectDBEditor',
  components: {
    APIObjectDataEditFieldList,
    APIObjectDataEditField,
    JSONTemplateDataEditFieldList,
    JSONTemplateDataEditField
  },
  props: props,
  data() {
    return {
      should_open: false,
      object_definition: null,
      object_definition_referencing_by_attribute: null,
      object_definition_reference_names_by_attribute: null,
      object_functions: null,
      only_emit_ta: true,
      show_result_int: false
    }
  },
  mounted() {
    const self = this
    this.$root.$on('bv::show::modal', (ev) => {
      if (ev === this.modal_id) {
        self.should_open = true
      }
    })
  },
  methods: {
    shown() {
      if (this.should_open) {
        this.should_open = false
      }
    },
    commit: APIObjectDBEditor.methods.commit,
    function2variant: TransactionUtil.function2variant,
    function2text: TransactionUtil.function2text,
    functionWithObject2text: TransactionUtil.functionWithObject2text,
  },
  computed: {
    computedPresets() {
      if (this.object_function === 'update') {
        return this.presets
      }
      if (this.presets) {
        const pres = {}
        Object.assign(pres, this.presets)
        for (const k in this.object_functions[this.object_function].parameters) {
          if ('new' in this.object_functions[this.object_function].parameters[k]) {
            if ('data_default' in this.object_functions[this.object_function].parameters[k].new && !(k in pres)) {
              pres[k] = this.object_functions[this.object_function].parameters[k].new.data_default
            }
          }
        }
        return pres
      }
      return {}
    }
  },
  watch: {
    $props: {
      immediate: true,
      deep: true,
      async handler() {
        this.show_result_int = this.show_result
        if (this.object_fq_name == null || (this.object_definition != null && this.object_fq_name === this.object_definition.fq_name)) {
          this.$emit('ready')
          if (this.should_open) {
            this.$root.$nextTick(() => this.$root.$emit('bv::show::modal', this.modal_id))
          }
          return
        }
        const res = await TransactionUtil.getObjectMetadata(this, this.object_fq_name)
        if (res === null) {
          return
        }
        this.object_definition = res.object_definition
        this.object_functions = res.object_functions
        this.object_definition_reference_names_by_attribute = res.object_definition_reference_names_by_attribute
        this.object_definition_referencing_by_attribute = res.object_definition_referencing_by_attribute
        this.$emit('ready')
        if (this.should_open) {
          this.$root.$nextTick(() => this.$root.$emit('bv::show::modal', this.modal_id))
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
