import Vuex from 'vuex'
import APIUtil from '@/util/apiutil'
import WAPITransactionSerice from '@/api-services/transaction.service'
import sharedMutations from 'vuex-shared-mutations'
import i18n from './i18n'
import Vue from 'vue'
import SystemInfoService from '@/api-services/system_info.service'
import NETVSConfig from '@/../netvs.config'
import {EventBus} from '@/eventbus'
import LoginService from '@/api-services/login.service'

Vue.use(Vuex)

function getTaListFromLocalStorage() {
  const ta_list = JSON.parse(window.localStorage.getItem('ta_list'))
  if (ta_list && ta_list.length > 0) {
    for (let i = 0; i < ta_list.length; i++) {
      let chg = false
      if (!('ta_type' in ta_list[i]) || !ta_list[i].ta_type) {
        chg = true
        ta_list[i].ta_type = 'db_editor_simple'
      }
      if (chg) {
        window.localStorage.setItem('ta_list', JSON.stringify(ta_list))
      }
    }
    return ta_list
  } else {
    return []
  }
}

const NetvsVuex = new Vuex.Store({
  state: {
    current_api_version: null,
    home_grid: window.localStorage.getItem('home_grid') ? JSON.parse(window.localStorage.getItem('home_grid')) : true,
    refreshHandleGPK: null,
    refreshHandleObjType: null,
    refreshHandleVariant: null,
    ot_lang_attr_def: {},
    xup_perm: {},
    init_fail: false,
    init_fail_info: null,
    spec_ready: true, // !(new Date().getMonth() === 3 && new Date().getDate() === 1),
    user: null,
    token: JSON.parse(window.localStorage.getItem('token')) || null,
    ta_list: getTaListFromLocalStorage(),
    netdb_axios_config: null,
    done: JSON.parse(window.localStorage.getItem('done')) || [],
    undone: JSON.parse(window.localStorage.getItem('undone')) || [],
    undo_redo_new_mutaion: JSON.parse(window.localStorage.getItem('undo_redo_new_mutation')) || true,
    transaction_result: JSON.parse(window.localStorage.getItem('transaction_result')) || null,
    ta_list_errors: JSON.parse(window.localStorage.getItem('ta_list_errors')) || [],
    executing_transaction: JSON.parse(window.localStorage.getItem('executing_transaction')) || false,
    show_sidebar_left: JSON.parse(window.localStorage.getItem('show_sidebar_left')) || true,
    show_sidebar_right: JSON.parse(window.localStorage.getItem('show_sidebar_right')) || false,
    reload_count: 0,
    db_editor_cache: JSON.parse(window.localStorage.getItem('db_editor_cache')) || {},
    db_editor_data_type_cache: null,
    expert: JSON.parse(window.localStorage.getItem('expert')) || false,
    developer: JSON.parse(window.localStorage.getItem('developer')) || false,
    enable_db_editor_cache: window.localStorage.getItem('enable_db_editor_cache') === null ? true : window.localStorage.getItem('enable_db_editor_cache') === 'true',
    keep_ta: JSON.parse(window.localStorage.getItem('keep_ta')) || false,
    macfinder_jobs: JSON.parse(window.localStorage.getItem('macfinder_jobs')) || [],
    ta_su_user: null,
    impersonate_user: JSON.parse(window.localStorage.getItem('impersonate_user')) || null,
    locale: JSON.parse(window.localStorage.getItem('locale')) || i18n.fallbackLocale[0],
    sys_alerts: [],
    maint_alerts: [],
    default_login_page: JSON.parse(window.localStorage.getItem('default_login_page')) || '/',
    sysinfo: null,
    sysinfo_mods_by_name: null,
    patch_request_edit_action: null,
    patch_request_edit_action_index: -1,
    patch_request_actions: JSON.parse(window.localStorage.getItem('patch_request_actions')) || [],
    search_history: JSON.parse(window.localStorage.getItem('search_history')) || [],
    is_ou_admin: false,
  },
  mutations: {
    setHomeGridMode(state, payload) {
      state.home_grid = payload
      window.localStorage.setItem('home_grid', JSON.stringify(state.home_grid))
    },
    // Only used in logout context!!!
    _unsetImpersonatingUser(state) {
      state.impersonate_user = null
      window.localStorage.removeItem('impersonate_user')
    },
    async updateImpersonatingUser(state, payload) {
      if (!state.token) {
        return
      }
      let lookup_success = false
      if (payload === null) {
        lookup_success = true
      } else {
        const lookup_result = await LoginService.get_mgr(state, payload)
        lookup_success = lookup_result.data && lookup_result.data.mgr_list && lookup_result.data.mgr_list.length > 0
      }
      if (lookup_success) {
        state.impersonate_user = payload
        window.localStorage.setItem('impersonate_user', JSON.stringify(state.impersonate_user))
        this.dispatch('refresh_session_info')
        state.reload_count++
      } else {
        EventBus.$emit('notify_user', {
          title: i18n.t('system.invalid_impersonate_user_event_title'),
          body: i18n.t('system.invalid_impersonate_user_event'),
          variant: 'warning'
        })
      }
    },
    updateDefaultLoginPage(state, payload) {
      state.default_login_page = payload
      window.localStorage.setItem('default_login_page', JSON.stringify(state.default_login_page))
    },
    updateSystemAlerts(state, payload) {
      state.sys_alerts = payload
    },
    updateMaintAlerts(state, payload) {
      state.maint_alerts = payload
    },
    populateInitState(state, payload) {
      state.init_fail = state.init_fail || payload.init_fail
      state.init_fail_info = payload.init_fail_info ? payload.init_fail_info : state.init_fail_info
      state.db_editor_data_type_cache = payload.data_types
      state.sysinfo = payload.sysinfo
      state.sysinfo_mods_by_name = payload.sysinfo_mods_by_name
      state.current_api_version = payload.current_api_version
    },
    newMacfinderJob(state, payload) {
      state.macfinder_jobs.push(payload)
      window.localStorage.setItem('macfinder_jobs', JSON.stringify(state.macfinder_jobs))
    },
    removeMacfinderJob(state, payload) {
      state.macfinder_jobs = state.macfinder_jobs.filter(j => j !== payload)
      window.localStorage.setItem('macfinder_jobs', JSON.stringify(state.macfinder_jobs))
    },
    toggle_expert(state) {
      state.expert = !state.expert
      window.localStorage.setItem('expert', JSON.stringify(state.expert))
    },
    toggle_developer(state) {
      state.developer = !state.developer
      window.localStorage.setItem('developer', JSON.stringify(state.developer))
    },
    toggle_db_editor_cache(state) {
      state.enable_db_editor_cache = !state.enable_db_editor_cache
      if (!state.enable_db_editor_cache) {
        state.db_editor_cache = {}
        window.localStorage.setItem('db_editor_cache', JSON.stringify({}))
      }
      window.localStorage.setItem('enable_db_editor_cache', JSON.stringify(state.enable_db_editor_cache))
    },
    toggle_keep_ta(state) {
      state.keep_ta = !state.keep_ta
      window.localStorage.setItem('keep_ta', JSON.stringify(state.expert))
    },
    emptyState(state) {
      state.ta_list = null
      window.localStorage.setItem('ta_list', JSON.stringify(state.ta_list))
    },
    setUndoRedoNewMutation(state, payload) {
      state.undo_redo_new_mutaion = payload
      window.localStorage.setItem('undo_redo_new_mutation', JSON.stringify(state.undo_redo_new_mutaion))
    },
    popUndoRedoDone(state) {
      state.done.pop()
      window.localStorage.setItem('done', JSON.stringify(state.done))
    },
    popUndoRedoUndone(state) {
      state.undone.pop()
      window.localStorage.setItem('undone', JSON.stringify(state.undone))
    },
    pushUndoRedoDone(state, payload) {
      state.done.push(payload)
      window.localStorage.setItem('done', JSON.stringify(state.done))
    },
    pushUndoRedoUndone(state, payload) {
      state.undone.push(payload)
      window.localStorage.setItem('undone', JSON.stringify(state.undone))
    },
    clearUndoRedoDone(state) {
      state.done = []
      window.localStorage.setItem('done', JSON.stringify(state.done))
    },
    clearUndoRedoUndone(state) {
      state.undone = []
      window.localStorage.setItem('undone', JSON.stringify(state.undone))
    },
    moveTaElements(state, payload) {
      state.ta_list.move(payload.from, payload.to)
      window.localStorage.setItem('ta_list', JSON.stringify(state.ta_list))
    },
    push_search_history(state, payload) {
      state.search_history = state.search_history.filter(e => e !== payload).slice(0, 100)
      state.search_history.unshift(payload)
      window.localStorage.setItem('search_history', JSON.stringify(state.search_history))
    },
    login(state, payload) {
      state.token = payload.token
      window.localStorage.setItem('token', JSON.stringify(state.token))
      if (state.token !== null) {
        state.netdb_axios_config = {
          baseURL: window.location.protocol + '//' + window.location.host,
          headers: {
            Authorization: 'Bearer ' + state.token.token,
            'Content-Type': 'application/json'
          }
        }
        this.dispatch('refresh_session_info')
      }
    },
    update_session_info(state, payload) {
      state.xup_perm = payload.xup_perm
      state.user = payload.user
      state.ot_lang_attr_def = payload.ot_lang_attr_def
      state.is_ou_admin = payload.is_ou_admin
    },
    logout(state) {
      state.user = null
      state.token = null
      state.xup_perm = {}
      if (!state.keep_ta) {
        state.ta_list = []
        window.localStorage.removeItem('ta_list')
      }
      state.undo_redo_new_mutaion = true
      state.undone = []
      state.done = []
      state.transaction_result = null
      state.ta_list_errors = []
      state.executing_transaction = false
      state.show_sidebar_right = false
      state.macfinder_jobs = []
      state.impersonate_user = null
      state.ot_lang_attr_def = {}
      state.patch_request_actions = []
      state.is_ou_admin = false
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('done')
      window.localStorage.removeItem('undone')
      window.localStorage.removeItem('undo_redo_new_mutation')
      window.localStorage.removeItem('transaction_result')
      window.localStorage.removeItem('ta_list_errors')
      window.localStorage.removeItem('executing_transaction')
      window.localStorage.removeItem('show_sidebar_right')
      window.localStorage.removeItem('db_editor_cache')
      window.localStorage.removeItem('macfinder_jobs')
      window.localStorage.removeItem('patch_request_actions')
      window.localStorage.removeItem('impersonate_user')
      window.localStorage.removeItem('patch_request_actions')
      state.netdb_axios_config = {
        headers: {
          Authorization: null,
          'Content-Type': 'application/json'
        }
      }
    },
    removeTransactionElement(state, payload) {
      for (let i = 0; i < state.ta_list.length; i++) {
        if (state.ta_list[i].uuid === payload.uuid) {
          state.ta_list.splice(i, 1)
          break
        }
      }
      window.localStorage.setItem('ta_list', JSON.stringify(state.ta_list))
    },
    replaceTransactionList(state, new_list) {
      state.ta_list = new_list
      window.localStorage.setItem('ta_list', JSON.stringify(state.ta_list))
    },
    addTransactionElement(state, payload) {
      if (state.ta_list == null) {
        state.ta_list = []
      }
      let update = false
      for (let i = 0; i < state.ta_list.length; i++) {
        if (state.ta_list[i].uuid === payload.old_uuid) {
          update = true
          Vue.set(state.ta_list, i, payload)
          break
        }
      }
      if (!update) {
        state.ta_list.push(payload)
      }
      window.localStorage.setItem('ta_list', JSON.stringify(state.ta_list))
    },
    emptyTransactionList(state) {
      state.ta_list = []
      state.ta_list_errors = []
      window.localStorage.setItem('ta_list', JSON.stringify(state.ta_list))
      window.localStorage.setItem('ta_list_errors', JSON.stringify(state.ta_list_errors))
    },
    setNavigationRefreshHandle(state, payload) {
      if (payload.variant && !('objType' in payload) && !('gpk' in payload)) {
        state.refreshHandleVariant = payload.variant
        return
      }
      state.refreshHandleObjType = payload.objType
      state.refreshHandleGPK = payload.gpk
      if ('variant' in payload) {
        state.refreshHandleVariant = payload.variant
      }
    },
    setTransactionResult(state, result) {
      state.transaction_result = result
      window.localStorage.setItem('transaction_result', JSON.stringify(state.transaction_result))
    },
    copyTransactionsWithErrors(state, payload) {
      if (state.ta_list_errors == null) {
        state.ta_list_errors = []
      }
      state.ta_list_errors = payload
      window.localStorage.setItem('ta_list_errors', JSON.stringify(state.ta_list_errors))
    },
    showSidebarLeft(state, show) {
      state.show_sidebar_left = show
      window.localStorage.setItem('show_sidebar_left', show)
    },
    showSidebarRight(state, show) {
      state.show_sidebar_right = show
      window.localStorage.setItem('show_sidebar_right', show)
    },
    setTransactionBusy(state, busy) {
      state.executing_transaction = busy
      window.localStorage.setItem('executing_transaction', busy)
    },
    removeTransactionResult(state) {
      state.transaction_result = null
      window.localStorage.removeItem('transaction_result')
      state.ta_list_errors = []
      window.localStorage.removeItem('ta_list_errors')
    },
    reloadRouterComp(state) {
      state.reload_count++
    },
    clearDBEditorData(state) {
      state.db_editor_cache = {version: state.current_api_version}
      window.localStorage.removeItem('db_editor_cache')
    },
    cacheDBEditorData(state, data) {
      if (!state.enable_db_editor_cache) {
        return
      }
      state.db_editor_cache[data.object_fq_name] = data
      window.localStorage.setItem('db_editor_cache', JSON.stringify(state.db_editor_cache))
    },
    setTaSuUser(state, data) {
      state.ta_su_user = data
    },
    setSpecReady(state, data) {
      state.spec_ready = data
    },
    updateLocale(state, data) {
      state.locale = data
      i18n.locale = state.locale
      window.localStorage.setItem('locale', JSON.stringify(state.locale))
    },
    setPatchRequestEditAction(state, data) {
      state.patch_request_edit_action = data.action
      if (data.index !== undefined) {
        state.patch_request_edit_action_index = data.index
      }
    },
    addPatchRequestAction(state, data) {
      state.patch_request_actions.push(data)
      window.localStorage.setItem('patch_request_actions', JSON.stringify(state.patch_request_actions))
    },
    removePatchRequestAction(state, index) {
      if (index > -1) {
        state.patch_request_actions.splice(index, 1)
        window.localStorage.setItem('patch_request_actions', JSON.stringify(state.patch_request_actions))
      }
    },
    clearPatchRequestActions(state) {
      state.patch_request_actions = []
      window.localStorage.setItem('patch_request_actions', JSON.stringify(state.patch_request_actions))
    },
    update_init_fail(state, payload) {
      state.init_fail = payload.init_fail ? payload.init_fail : state.init_fail
      state.init_fail_info = payload.init_fail_info ? payload.init_fail_info : state.init_fail_info
    }
  },
  getters: {
    patch_request_actions_count: state => {
      return state.patch_request_actions.length
    }
  },
  actions: {
    async doLogout({commit, state}) {
      commit('_unsetImpersonatingUser')
      if (state.token && state.token.gpk) {
        try {
          await LoginService.logout(state)
        } catch (e) {
          window.console.error('silenced doLogout error', e)
        }
      }
      commit('logout')
    },
    async testTransaction({commit, state}) {
      try {
        commit('setTransactionBusy', true)
        const api_list = APIUtil.buildAPITaFromTaObjectArray(state)
        try {
          if (state.ta_su_user == null) {
            await WAPITransactionSerice.executeDry(state, api_list)
          } else {
            await WAPITransactionSerice.executeDrySu(state, api_list, state.ta_su_user)
          }
          commit('setTransactionResult', {
            type: 'test-success'
          })
        } catch (e) {
          window.console.error(e)
          const failed_stmt = APIUtil.getAPIErrorNameFromDBException(e.response.data.exception)
          let pos = null
          if (failed_stmt) {
            for (const ta of state.ta_list) {
              if (failed_stmt.startsWith(ta.uuid)) {
                pos = ta
                break
              }
            }
          }
          // Create a copy of the ta_list in ta_list_errors
          commit('copyTransactionsWithErrors', JSON.parse(JSON.stringify(state.ta_list)))
          commit('setTransactionResult', {
            type: 'error',
            error: e.response.data,
            uuid: (typeof e.response.data === 'object' && 'exception' in e.response.data) && pos ? pos.uuid : null
          })
        }
      } finally {
        commit('setTransactionBusy', false)
        if (state.ta_su_user != null) {
          commit('setTaSuUser', null)
        }
      }
    },
    async executeTransaction({commit, state}) {
      commit('setTransactionBusy', true)

      const api_list = APIUtil.buildAPITaFromTaObjectArray(state)
      try {
        const res = await WAPITransactionSerice.execute(state, api_list)
        commit('setTransactionResult', {
          type: 'success',
          result: res.data,
          ta_list: state.ta_list
        })
        commit('emptyTransactionList')
        commit('clearUndoRedoDone')
        commit('clearUndoRedoUndone')
        commit('setUndoRedoNewMutation', true)
      } catch (e) {
        window.console.debug(e.response)
        const failed_stmt = APIUtil.getAPIErrorNameFromDBException(e.response.data.exception)
        let pos = null
        if (failed_stmt) {
          for (const ta of state.ta_list) {
            if (failed_stmt.startsWith(ta.uuid)) {
              pos = ta
              break
            }
          }
        }
        // Create a copy of the ta_list in ta_list_errors
        commit('copyTransactionsWithErrors', JSON.parse(JSON.stringify(state.ta_list)))
        commit('setTransactionResult', {
          type: 'error',
          error: e.response.data,
          uuid: (typeof e.response.data === 'object' && 'exception' in e.response.data) && pos ? pos.uuid : null
        })
      } finally {
        commit('setTransactionBusy', false)
      }
    },
    async refresh_session_info(context) {
      try {
        const response = await SystemInfoService.getSessionInfo(context.state)
        const session_info = response.data
        context.commit('update_session_info', {
          xup_perm: APIUtil.extract_permissions(session_info.permission_list, session_info.permission_descr),
          user: session_info.mgr_list[0],
          ot_lang_attr_def: APIUtil.dict_by_value_of_array(session_info.ot_lang_attr_def_list, 'object_type_fq_name'),
          is_ou_admin: session_info.mgr2ou_list.length > 0
        })
      } catch (e) {
        let init_fail_info
        let init_fail
        window.console.error('failed receiving session info', e)
        if (!('response' in e)) {
          init_fail_info = e.message
        } else {
          if (e.response.data instanceof Object && ('exception' in e.response.data && 'error' in e.response.data.exception)) {
            if (e.response.data.exception.error.code === 2) {
              context.commit('updateImpersonatingUser', null)
              return
            } else if (e.response.data.exception.error_type.name !== 'authentication_error') {
              init_fail = true
            } else {
              window.localStorage.removeItem('token')
            }
          } else {
            init_fail = true
          }
          window.console.log(e.response)
          if (e.response.status === 404) {
            init_fail_info = {
              error_type: {
                description: 'API cannot be reached. (404)',
              },
              error: {
                description: 'Make sure your configuration matches the current API version.',
                details: `Current base URL: ${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}`
              }
            }
            init_fail = true
          }
        }
        context.commit('update_init_fail', {
          init_fail: init_fail,
          init_fail_info: init_fail_info
        })
      }
    }
  },
  plugins: [
    // Synced mutation between tabs
    sharedMutations(
      {
        predicate:
          [
            'pushUndoRedoUndone',
            'popUndoRedoUndone',
            'clearUndoRedoUndone',
            'setUndoRedoNewMutation',
            'clearUndoRedoDone',
            'popUndoRedoDone',
            'emptyState',
            'login',
            'logout',
            'addTransactionElement',
            'removeTransactionElement',
            'emptyTransactionList',
            'removeTransactionResult',
            'copyTransactionsWithErrors',
            'setTransactionBusy',
            'moveTaElements',
            'replaceTransactionList',
            'setTransactionResult',
            'newMacfinderJob',
            'toggle_expert',
            'toggle_developer',
            'toggle_db_editor_cache',
            'toggle_keep_ta',
            'updateLocale',
            'reloadRouterComp',
            'setPatchRequestEditAction',
            'addPatchRequestAction',
            'removePatchRequestAction',
            'clearPatchRequestActions',
            'updateImpersonatingUser',
            'updateDefaultLoginPage',
            'setHomeGridMode',
            'update_session_info',
          ]
      }
    )
  ]
})
// Undo-Redo-watched mutations
const watchedMutations = ['addTransactionElement', 'removeTransactionElement', 'emptyTransactionList', 'moveTaElements', 'replaceTransactionList']

export {
  watchedMutations,
  NetvsVuex
}
